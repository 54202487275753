import tw from "tailwind-styled-components"

const Heading = tw.h1`
       text-cyan
       text-4xl
       md:text-6xl
       p-2
       my-2
       md:my-4
       w-full
    `

export default Heading
