import React, { useRef, useEffect } from "react"
import { ORANGE, N_CYAN, N_PINK } from "../../constants"
import { Object2d } from "../../util"

class Triangle extends Object2d {
  public color = ORANGE
  public vertices = [100, 175, 1, 50, 100, 1, 150, 100, 1]

  public draw = (context: CanvasRenderingContext2D) => {
    /**
     * Draw triangle from current object state
     */
    context.strokeStyle = this.color
    context.beginPath()
    context.moveTo(this.vertices[0], this.vertices[1])
    context.lineTo(this.vertices[3], this.vertices[4])
    context.lineTo(this.vertices[6], this.vertices[7])
    context.lineTo(this.vertices[0], this.vertices[1])
    context.stroke()
  }
}

const StackedTriangles: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error("no canvas element")
      return
    }

    let context = canvas.getContext("2d")

    if (!context) {
      console.error("could not get context")
      return
    }

    context.lineWidth = 2
    const COLORS = [ORANGE, N_CYAN, N_PINK]

    const triangle = new Triangle()
    triangle.color = COLORS[2]
    triangle.draw(context)

    // Create and draw 6 triangles
    for (let i = 1; i < 5; i++) {
      const triangle = new Triangle()
      triangle.color = COLORS[i % 3]
      triangle.translate(0, i * -15)
      triangle.draw(context)
    }
  })

  return (
    <div className="w-full h-full flex items-center justify-center">
      <canvas height="200" width="200" ref={canvasRef} />
    </div>
  )
}

export default StackedTriangles
