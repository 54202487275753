import { WebGLRenderer } from "three"

function resizeRenderer(renderer: WebGLRenderer) {
  /* Resize renderer to match display size */
  const canvas = renderer.domElement
  const width = canvas.clientWidth
  const height = canvas.clientHeight
  const needResize = canvas.width !== width || canvas.height !== height
  if (needResize) {
    renderer.setSize(width, height, false)
  }
  return needResize
}

export default resizeRenderer
