import tw from "tailwind-styled-components"

const PiList = tw.ul`
    text-pink-100
    text-xl
    px-10 
    my-3
    leading-8 
    list-disc
  `

export default PiList
