const tranlationMatrix = (tx: number, ty: number) => {
  /**
   * Generate a 2d translation matrix from a pair of numbers.
   *
   * @param {number} tx x-axis translation
   * @param {number} ty y-axis translation
   */
  return [1, 0, 0, 0, 1, 0, tx, ty, 1]
}

export default tranlationMatrix
