import React, { useRef, useEffect } from "react"
import { ORANGE, N_PINK, N_CYAN } from "../../constants"
type Orientation = 1 | -1

export class Circle {
  /**
   * A utility class to help draw and shift circles
   */
  private C_RAD = 2 * Math.PI
  private children: Circle[] = []
  constructor(
    private x: number, // x coordinate of circle center
    private y: number, // y coordinate of circle center
    private radius: number,
    private _color: string,
    private orientation: Orientation, // direction of axis along which inner circles are placed
    private dChildren = false
  ) {
    if (this.dChildren) {
      this.genChildren()
    }
  }

  draw = (context: CanvasRenderingContext2D) => {
    context.beginPath()
    context.strokeStyle = this.color
    context.arc(this.x, this.y, this.radius, 0, this.C_RAD)
    context.stroke()

    this.children.forEach((child) => child.draw(context))
  }

  rotate = (angle: number) => {
    /**
     * Set new center coordinates for the circle as if it's being rotated around a point by the specified angle
     *
     * @param {number} angle
     */
    const new_x = this.x * Math.cos(angle) - this.y * Math.sin(angle)
    const new_y = this.x * Math.sin(angle) + this.y * Math.cos(angle)
    this.x = new_x
    this.y = new_y

    this.children.forEach((child) => child.rotate(angle))
  }

  public set color(color: string) {
    /**
     * Set the color for a circle and its children
     *
     * @param {string} color
     */

    this._color = color
    this.children.forEach((child) => (child.color = color))
  }

  public get color() {
    return this._color
  }

  genChildren = () => {
    /**
     * Generate a set of smaller nested circles with the center of each on a shared axis
     *
     */
    const outerRad = this.radius / 7
    const innerRad = this.radius / 14

    const outerOffset = this.orientation * outerRad * 4.5
    const innerOffset = this.orientation * innerRad * 3.5

    // larger outer child circle
    const child1 = new Circle(
      this.orientation === 1 ? this.x : this.x + outerOffset,
      this.orientation === 1 ? this.y + outerOffset : this.y,
      outerRad,
      this.color,
      this.orientation
    )
    this.children.push(child1)

    // Smaller inner child circle
    const child2 = new Circle(
      this.orientation === 1 ? this.x : this.x + innerOffset,
      this.orientation === 1 ? this.y + innerOffset : this.y,
      innerRad,
      this.color,
      this.orientation
    )
    this.children.push(child2)
  }
}

const StencilCircles: React.FC = () => {
  /**
   * Draws a series of two sets of multi colored circles. Kind of like a
   * Spirograph. End up looking like 2 adjacent tubes.
   *
   * Basic idea and color scheme yoinked from https://codepen.io/zimjs/pen/rNewpwP
   */
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error("no canvas element")
      return
    }

    let context = canvas.getContext("2d")

    if (!context) {
      console.error("could not get context")
      return
    }

    // canvas dimensions
    const { width, height } = canvas.getBoundingClientRect()
    const needResize = canvas.width !== width || canvas.height !== height

    if (needResize) {
      canvas.width = width
      canvas.height = height
    }

    const MAX_LOOPS = 75
    context.lineWidth = 2

    // circle size and color constants
    const COLORS = [N_CYAN, N_PINK, ORANGE]
    const OUTER_RADIUS = height * 0.75
    const OFFSET_Y = height / 12

    // circles on the left
    const left = new Circle(
      width - OUTER_RADIUS * 4,
      height - OFFSET_Y,
      OUTER_RADIUS,
      COLORS[0],
      1,
      true
    )
    left.draw(context)

    // circles on the right
    const right = new Circle(
      width - OUTER_RADIUS * 0.8,
      -OFFSET_Y,
      OUTER_RADIUS,
      COLORS[1],
      -1,
      true
    )
    right.draw(context)

    let last = 0
    let loopCount = 0
    let colorsSet = true // whether or not new colors were set last loop

    // render loop
    const render = (time: number) => {
      if (loopCount > MAX_LOOPS) return // limit loops

      const diff = time - last // time since last circle was drawn

      // if a circle was drawn < 100 ms ago, do nothing, restart loop
      if (diff < 50) {
        requestAnimationFrame(render)
        return
      }

      // Increment count of circles drawn
      loopCount += 1

      // Store timestamp of time last circle was drawn
      last = time

      // rotate circles
      left.rotate((-2 * Math.PI) / 120)
      right.rotate((2 * Math.PI) / 320)

      // Change colors only if color wasn't changed last loop
      if (colorsSet) {
        colorsSet = false
      } else {
        colorsSet = true
        left.color = COLORS[loopCount % 3]
        right.color = COLORS[(loopCount + 1) % 3]
      }

      right.draw(context!)
      left.draw(context!)

      requestAnimationFrame(render)
    }
    requestAnimationFrame(render)
  }, [])

  return (
    <div className="w-full h-full flex items-center justify-center">
      <canvas className="w-full" height="200" width="600" ref={canvasRef} />
    </div>
  )
}

export default StencilCircles
