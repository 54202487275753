import {
  ConeGeometry,
  Group,
  LineBasicMaterial,
  LineSegments,
  EdgesGeometry,
  Float32BufferAttribute,
  Color,
  BufferGeometry,
} from "three"
import {
  CONE_RADIUS,
  CONE_HEIGHT,
  CONE_OFFSET,
  N_CYAN,
  N_PINK,
  ORANGE,
} from "../constants"

const diamond = (): [
  Group,
  LineBasicMaterial,
  BufferGeometry,
  BufferGeometry
] => {
  /**
   *  Assemble group of ConeGeometry obects to make a shape that looks like a diamond.
   *  This function is responsible for setting the shape and position of the geometry.
   *  This includes responsive behaviors.
   *
   *  There are 2 cones in the final figure. The first one is cloned, then flipped upside down.
   *  The two are then merged to make a diamond-like figure.
   *
   * @param {number} width
   *
   */
  const group = new Group()
  const material = new LineBasicMaterial({ vertexColors: true })

  // proportions for 1 cone
  const radialSegments = 7
  const sides = radialSegments * 4

  const cone = new ConeGeometry(CONE_RADIUS, CONE_HEIGHT, radialSegments)

  // use EdgesGeometry so that inner vertices aren't rendered
  const edges = new EdgesGeometry(cone)

  // Set array of vertex colors. Values here don't matter. They'll be
  // changed on first frame of animation
  const colors = []
  const COLOR_SET = [ORANGE, N_PINK, N_CYAN]
  for (let i = 0; i < sides; i++) {
    const clr = new Color(COLOR_SET[i % 3])
    colors.push(clr.r, clr.g, clr.b)
  }

  edges.setAttribute("color", new Float32BufferAttribute(colors, 3))

  // clone the cone
  const clone = edges.clone()

  // Flip the clone and shift it down to bottoms of the shapes are merged
  clone.translate(0, CONE_HEIGHT, 0)
  clone.rotateZ(Math.PI)

  const lineTop = new LineSegments(edges, material)
  const lineBottom = new LineSegments(clone, material)

  group.add(lineTop)
  group.add(lineBottom)
  group.position.y = CONE_OFFSET

  return [group, material, edges, clone]
}

export default diamond
