import tw from "tailwind-styled-components"

const Paragraph = tw.p`
    text-pink-100
    p-2
    my-2
    w-full
    md:text-xl
    md:leading-relaxed
`

export default Paragraph
