import { WebGLRenderer } from "three"

const createRenderer = (canvas: HTMLCanvasElement): WebGLRenderer => {
  /* Create a WebGl rendere and attach it to canvas element after resize */
  const renderer = new WebGLRenderer({ canvas, preserveDrawingBuffer: true })
  renderer.setPixelRatio(window.devicePixelRatio)

  return renderer
}

export default createRenderer
