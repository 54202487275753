import React, { useState } from "react"
import { CYAN, N_PINK, ORANGE } from "../../constants"

const ScrollTop: React.FC = () => {
  const [bouncing, setBouncing] = useState(false)

  const scrollTop = () => {
    setBouncing(true)
    window.scroll({ top: 0, left: 0, behavior: "smooth" })
  }

  return (
    <button
      className={
        bouncing
          ? "bg-gray-900 hover:bg-gray-800 p-0 fixed right-12 bottom-8 animate-bounce-once"
          : "bg-gray-900 hover:bg-gray-800 p-0 fixed right-12 bottom-8"
      }
      onClick={scrollTop}
      onAnimationEnd={() => setBouncing(false)}
    >
      <svg width="100" height="110">
        <line x1="20" y1="90" x2="50" y2="60" stroke={N_PINK} strokeWidth="2" />
        <line x1="50" y1="60" x2="80" y2="90" stroke={N_PINK} strokeWidth="2" />
        <line x1="20" y1="70" x2="50" y2="40" stroke={CYAN} strokeWidth="2" />
        <line x1="50" y1="40" x2="80" y2="70" stroke={CYAN} strokeWidth="2" />
        <line x1="20" y1="50" x2="50" y2="20" stroke={ORANGE} strokeWidth="2" />
        <line x1="50" y1="20" x2="80" y2="50" stroke={ORANGE} strokeWidth="2" />
      </svg>
    </button>
  )
}

export default ScrollTop
