import React, { useEffect, useRef, useState } from "react"
import { Scene, PerspectiveCamera, Vector2 } from "three"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"

// local
import {
  resizeRenderer,
  createRenderer,
  diamond,
  eulerRotation,
} from "../../util"
import { WebGLError } from "../"

const createCamera = (width: number, height: number): PerspectiveCamera => {
  const fov = 75
  const aspect = width / height
  const camera = new PerspectiveCamera(fov, aspect)
  camera.position.z = 800

  return camera
}

const Canvas: React.FC = () => {
  /*
   * Canvas element for the home page
   */
  const [webGLError, setWebGLError] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error("Could not get canvas element")
      setWebGLError(true)
      return
    }

    // Check webgl compatibility
    if (!window.WebGLRenderingContext && !canvas.getContext("webgl")) {
      console.error("Device is does not support WebGL")
      setWebGLError(true)
      return
    }

    // Get canvas dimensions
    const { width, height } = canvas.getBoundingClientRect()

    // create renderer
    const renderer = createRenderer(canvas)

    // create scene and camera
    const camera = createCamera(width, height)
    const scene = new Scene()

    // create diamond obect and add it to scene
    const [diamondGroup, material, geo1, geo2] = diamond()
    scene.add(diamondGroup)

    // add post-processing pass
    const composer = new EffectComposer(renderer)
    composer.setSize(width, height)
    composer.addPass(new RenderPass(scene, camera))

    const bloomPass = new UnrealBloomPass(new Vector2(width, height), 1.5, 0, 0)

    composer.addPass(bloomPass)

    // render loop
    const render = (time: number) => {
      time *= 0.001 // to seconds

      // resize if required
      if (resizeRenderer(renderer)) {
        const domElement = renderer.domElement
        camera.aspect = domElement.clientWidth / domElement.clientHeight
        camera.updateProjectionMatrix()
      }

      // set rotation of diamond
      diamondGroup.setRotationFromEuler(eulerRotation(time))

      // render scene
      composer.render()

      requestAnimationFrame(render)
    }

    // initial render call
    requestAnimationFrame(render)

    return () => {
      material.dispose()
      geo1.dispose()
      geo2.dispose()
    }
  }, [])

  return webGLError ? (
    <WebGLError />
  ) : (
    <canvas
      className="w-screen"
      style={{ height: "calc(100vh - 18em)" }}
      ref={canvasRef}
    />
  )
}

export default Canvas
