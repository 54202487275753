import { Euler } from "three"

const eulerRotation = (time: number): Euler => {
  /**
   * Create a euler object that defines the direction and velocity
   * of rotation for the home page diamond
   *
   * @param {number} time time in seconds since start of animation
   *
   * @return {Euler} Euler obejct
   */

  const TWO_PI = Math.PI * 2
  const rotation = 0.1 * Math.sin((TWO_PI * time * 10) / 60 / 6)
  return new Euler(0, time, rotation, "XYZ")
}

export default eulerRotation
