module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Charles Desbiens - Yet another portfolio site","short_name":"Charles","start_url":"/","background_color":"##000000","theme_color":"##FCE7F3","display":"standalone","icon":"src/images/android-chrome-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5c8aff71e813e5bad4a30b7dad257b13"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
