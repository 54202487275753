import React from "react"
import { FiAlertOctagon } from "@react-icons/all-files/fi/FiAlertOctagon"

const WebGLError: React.FC = () => {
  return (
    <section
      className="flex flex-col items-center justify-center gap-y-12"
      style={{ height: "calc(100vh - 12rem)" }}
    >
      <FiAlertOctagon className="text-red-400 text-7xl" />
      <section className="text-cyan text-3xl">
        This device does not support WebGL :(
      </section>
    </section>
  )
}

export default WebGLError
