import React from "react"
import { DiGithubBadge } from "@react-icons/all-files/di/DiGithubBadge"
import { DiStackoverflow } from "@react-icons/all-files/di/DiStackoverflow"

const Footer: React.FC = () => {
  const year = new Date().getFullYear()
  return (
    <footer
      className="
            border-gray-900
            border-t-2
            w-full
            max-h-80
            flex 
            flex-col
            md:container 
            md:mx-auto 
            md:w-2/3 
            xl:w-1/2 
            pl-6
            pt-4
            my-8
            "
    >
      <section
        className="
            self-center 
            flex 
            flex-row
            gap-x-6
            text-5xl 
            text-orange
            "
      >
        <a
          href="https://github.com/baremaximum"
          target="_blank"
          rel="noreferrer"
        >
          <DiGithubBadge />
        </a>
        <a
          href="https://stackoverflow.com/users/8218726/charles-desbiens"
          target="_blank"
          rel="noreferrer"
        >
          <DiStackoverflow />
        </a>
      </section>
      <section className="text-gray-600 text-xl mt-8">
        &copy; Charles Desbiens - {year}
      </section>
    </footer>
  )
}

export default Footer
