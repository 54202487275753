import tw from "tailwind-styled-components"

const Article = tw.article`
    flex 
    flex-col 
    md:container 
    mx-auto 
    md:w-2/3 
    xl:w-1/2
    px-8
    md:px-0
`

export default Article
