import React from "react"
import { Link } from "gatsby"

const Navbar: React.FC = () => {
  return (
    <div className="flex flex-row md:container mx-auto md:w-2/3 xl:w-1/2 md:px-5 md:py-4 px-3 py-2">
      <Link
        className="text-3xl md:text-6xl font-black text-center text-pink-100 w-28"
        to="/"
      >
        C
      </Link>
      <nav className="flex flex-row items-center justify-evenly md:text-xl text-sm tracking-wide w-full">
        <Link
          activeClassName="active"
          to="/about-me"
          className="link w-20 md:w-32 text-center relative"
        >
          About Me
        </Link>
        <Link
          activeClassName="active"
          to="/tech-stack"
          className="link w-20 md:w-32 text-center relative"
        >
          Tech Stack
        </Link>
        <Link
          activeClassName="active"
          to="/portfolio"
          className="link w-20 md:w-32 text-center relative"
        >
          Portfolio
        </Link>
        <Link
          activeClassName="active"
          to="/contact"
          className="link w-20 md:w-32 text-center relative"
        >
          Contact
        </Link>
      </nav>
    </div>
  )
}

export default Navbar
