import React from "react"
import tw from "tailwind-styled-components"
import PiList from "../portfolio-item-list/PortfolioItemList"

interface IPItem {
  name: string
  tools: string[]
}

const PiName = tw.h3`
  text-cyan
    text-4xl
    p-2
    mt-9
    mb-4
  `

const PortfolioItem: React.FC<IPItem> = ({ name, tools, children }) => {
  /**
   * Displays an item in my portfolio, with the name of the project, and the
   * list of tools, and any children directly below.
   *
   * @param {string} name the name of the project
   * @param {string[]} tools names of the tools used on the project
   */

  // build list of li elements from tools prop
  const toolsList = tools.map((tool, index) => <li key={index}>{tool}</li>)

  return (
    <section className="my-6">
      <PiName>{name}</PiName>
      <h4 className="text-pink-100 underline text-2xl pl-3">Made with:</h4>
      <PiList>{toolsList}</PiList>
      {children}
    </section>
  )
}

export default PortfolioItem
