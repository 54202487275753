const rotationMatrix = (angleRadians: number) => {
  /**
   * Generate a rotation matrix from an angle
   *
   * @param {number} angleRadians angle of rotation in radians
   */
  const c = Math.cos(angleRadians)
  const s = Math.sin(angleRadians)
  return [c, -s, 0, s, c, 0, 0, 0, 1]
}

export default rotationMatrix
