const scalingMatrix = (sx: number, sy: number) => {
  /**
   * Matrix that can be used to scale another matrix
   *
   * @param {number} sx value to scale by along x axis
   * @param {number} sy value to scale by along y axis
   */

  return [sx, 0, 0, 0, sy, 0, 0, 0, 1]
}

export default scalingMatrix
