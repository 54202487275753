import React, { useEffect, useRef } from "react"
import { Object2d } from "../../util"
import { N_CYAN, N_PINK, ORANGE } from "../../constants"

const DIVISOR = 9 // smaller = wider spread
const MAX_LOOPS = 15
const LINE_WIDTH = 2
const DRAW_DELAY = 75 // time to wait before drawing next set of triangles
const HEIGHT_RATIO = 75 / 200

class Triangle extends Object2d {
  /**
   * A helper class that provides a basis for drawing and transforming
   * triangles.
   *
   */
  public y1: number
  public x2: number
  public y2: number
  private initialVertices: number[]
  public vertices: number[]

  constructor(
    public canvasWidth: number,
    public canvasHeight: number,
    public color: string = ORANGE
  ) {
    super()
    this.y1 = this.canvasHeight * HEIGHT_RATIO
    this.x2 = this.y1 / 3
    this.y2 = this.x2 * 2
    this.initialVertices = [0, 0, 1, 0, this.y1, 1, this.x2, this.y2, 1]
    this.vertices = [0, 0, 1, 0, this.y1, 1, this.x2, this.y2, 1]
  }

  public reset = () => {
    /**
     * Reset vertices to canvas origin.
     */
    this.vertices = this.initialVertices
  }

  public draw = (context: CanvasRenderingContext2D) => {
    /**
     * Draw triangle from current object state
     */
    context.strokeStyle = this.color
    context.beginPath()
    context.moveTo(this.vertices[0], this.vertices[1])
    context.lineTo(this.vertices[3], this.vertices[4])
    context.lineTo(this.vertices[6], this.vertices[7])
    context.lineTo(this.vertices[0], this.vertices[1])
    context.stroke()
  }
}

class TGroup {
  /**
   * A helper class that simplifies applying the same operation to
   * many child obects. This allows you to manipulate several triangles as
   * though they were a single object.
   */
  private initialRotation1 = -Math.PI / DIVISOR
  private initialRotation2 = this.initialRotation1 * 2
  private colors = [ORANGE, N_CYAN, N_PINK]
  private triangles: Triangle[]
  constructor(
    public scale: number,
    public canvasWidth: number,
    public canvasHeight: number
  ) {
    this.triangles = [
      new Triangle(this.canvasWidth, this.canvasHeight, this.colors[0]),
      new Triangle(this.canvasWidth, this.canvasHeight, this.colors[1]),
      new Triangle(this.canvasWidth, this.canvasHeight, this.colors[2]),
    ]

    this.triangles.forEach((t) => t.scale(scale, scale))
  }

  public reset = () => {
    /**
     * Move all member triangles back to canvas origin.
     */
    this.triangles.forEach((t) => t.reset())
    this.triangles.forEach((t) => t.scale(this.scale, this.scale))
  }

  public translate = ([tx, ty]: [number, number]) => {
    /**
     * Translate all member triangles by specified amounts along x and y axes.
     */
    this.triangles.forEach((t) => t.translate(tx, ty))
  }

  public fanOut = (offset: number) => {
    /**
     * Apply the rotation that gives a fanning out kind of appearence to the children
     */
    const rotation = (offset * Math.PI) / DIVISOR
    this.triangles[0].rotate(rotation)
    this.triangles[1].rotate(rotation + this.initialRotation1)
    this.triangles[2].rotate(rotation + this.initialRotation2)
  }

  public cycleColors(loopCount: number) {
    /**
     * Use loopCount % 3 to cycle between the different colors and apply them
     * to the member triangles in order.
     */

    this.triangles[1].color = this.colors[loopCount % 3]
    this.triangles[2].color = this.colors[(loopCount + 1) % 3]
    this.triangles[0].color = this.colors[(loopCount + 2) % 3]
  }

  public draw = (context: CanvasRenderingContext2D) => {
    this.triangles.forEach((t) => t.draw(context))
  }
}

const FanningTriangles: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current

    if (!canvas) {
      console.error("no canvas element")
      return
    }

    let context = canvas.getContext("2d")

    if (!context) {
      console.error("could not get context")
      return
    }

    // canvas dimensions
    const { width, height } = canvas.getBoundingClientRect()
    const needResize = canvas.width !== width || canvas.height !== height

    if (needResize) {
      canvas.width = width
      canvas.height = height
    }

    // canvas dimensions
    context.lineWidth = LINE_WIDTH

    // create groups
    const group1 = new TGroup(4.4, width, height)

    //initial translations from origin
    const translate1: [number, number] = [width / 2, height / 2]

    // initial spread
    group1.fanOut(0)

    // initial translation
    group1.translate(translate1)

    // initial drawing
    group1.draw(context)

    let last = 0 // time last triangle was drawn in ms since start of loop
    let loopCount = 0
    let colorsSet = true // was a color changed last time a triangle was drawn

    const render = (time: number) => {
      if (loopCount > MAX_LOOPS) return // limit loops

      const diff = time - last // time since last circle was drawn

      // If a circle was drawn < 100 ms ago, do nothing, restart loop
      if (diff < DRAW_DELAY) {
        requestAnimationFrame(render)
        return
      }

      loopCount += 1
      last = time

      // Reset all to origin
      group1.reset()

      // Rotate based on loopCount
      group1.fanOut(loopCount)

      // Translate back to destination
      group1.translate(translate1)

      // Change colors only if color wasn't changed last loop
      if (colorsSet) {
        colorsSet = false
      } else {
        colorsSet = true
        group1.cycleColors(loopCount)
      }

      group1.draw(context!)
      requestAnimationFrame(render)
    }

    requestAnimationFrame(render)
  }, [])
  return (
    <div className="w-full h-full flex items-center justify-center">
      <canvas className="w-full" width="600" height="200" ref={canvasRef} />
    </div>
  )
}

export default FanningTriangles
