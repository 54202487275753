import React from "react"
import { Helmet } from "react-helmet"

const Body: React.FC = () => (
  <Helmet>
    <title>Charles Desbiens - Full Stack Developer</title>
    <meta
      name="description"
      content="Full stack developer portfolio by Charles Desbiens"
    />
    <body className="bg-black text-cyan w-screen overflow-x-hidden" />
  </Helmet>
)

export default Body
