import { translationMatrix, dotProduct, scalingMatrix, rotationMatrix } from "."

abstract class Object2d {
  /**
   * An abstract class that contains common transformation methods.
   */
  abstract vertices: number[]
  abstract color: string

  public translate = (tx: number, ty: number) => {
    this.transform(translationMatrix(tx, ty))
  }

  public transform = (m: number[]) => {
    /**
     * Apply a transformation matrix to the triangle's vertices
     */
    const newVertices = dotProduct(m, this.vertices)
    this.vertices = newVertices
  }

  public scale = (sx: number, sy: number) => {
    /**
     * Create scaling matrix and apply transformation
     */
    this.transform(scalingMatrix(sx, sy))
  }

  public rotate = (angle: number) => {
    this.transform(rotationMatrix(angle))
  }

  public abstract draw = (context: CanvasRenderingContext2D): void => {}
}

export default Object2d
