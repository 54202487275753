import tw from "tailwind-styled-components"

const List = tw.ul`
text-pink-100
p-2
mb-8
md:text-xl
leading-relaxed
list-disc 
`

export default List
